@use 'variables';

.component-reset {
    margin-top: 30px;
    margin-bottom: 30px;
    box-sizing: border-box;
    padding: 30px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    .reset-info {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .info-title {
            margin: 15px 0px;
        }
    }

    .reset-fields {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        .field {
            flex: 0 0 100%;
            margin-bottom: 30px;
            margin-right: 30px;
            @include variables.for-mediumUp-only {
                max-width: 250px;
            }
            label {
                display: block;
                font-size: 0.875rem;
                margin-bottom: 16px;
                @include variables.bold-font;
            }
            input {
                border-radius: 4px;
                border: 1px solid #e2e2e2;
                height: 40px;
                padding-left: 15px;
                width: 100%;
            }
        }
    }

    button {
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid #e2e2e2;
        flex: 0 0 120px;
        font-size: 0.875rem;
        height: 40px;
        padding: 10px 20px;
        @include variables.bold-font;
    }
}
